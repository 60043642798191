import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { pageTitle: "Zaloguj się do systemu", title: 'Zaloguj się do systemu' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Login.vue')
  },
  {
    path: '/hub',
    name: 'Hub',
    meta: { pageTitle: "Wybierz moduł - Lauron Sp. z o.o.", title: 'Wybierz moduł' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Hub.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "about" */ '../views/ManageUsers.vue'),
        meta: { pageTitle: "Zarządzaj użytkownikami - Lauron Sp. z o.o.", title: 'Zarządzaj użytkownikami systemu' }
      },
      {
        path: 'user',
        component: () => import(/* webpackChunkName: "about" */ '../views/ShowUserDetails.vue'),
        meta: { pageTitle: "Szczegóły konta - Lauron Sp. z o.o.", title: 'Szczegóły konta ' }
      },
      {
        path: 'invoices',
        component: () => import(/* webpackChunkName: "about" */ '../views/Invoices.vue'),
        meta: { pageTitle: "Zarządzaj fakturami - Lauron Sp. z o.o.", title: 'Zarządzaj fakturami' }
      },
      {
        path: 'uninvoicedTransactions',
        component: () => import(/* webpackChunkName: "about" */ '../views/UninvoicedTransactions.vue'),
        meta: { pageTitle: "Zarządzaj niezafakturowanymi transakcjami - Lauron Sp. z o.o.", title: 'Zarządzaj niezafakturowanymi transakcjami' },
      },
      {
        path: 'createInterestNote',
        component: () => import(/* webpackChunkName: "about" */ '../views/CreateInterestNote.vue'),
        meta: { pageTitle: "Zarządzaj notą odsetkową - Lauron Sp. z o.o.", title: 'Zarządzaj notą odsetkową' },
      },
      {
        path: 'interestNotes',
        component: () => import(/* webpackChunkName: "about" */ '../views/InterestNotes.vue'),
        meta: { pageTitle: "Zarządzaj notą odsetkową - Lauron Sp. z o.o.", title: 'Zarządzaj notą odsetkową' },
      },
      {
        path: 'gainsLosses',
        component: () => import(/* webpackChunkName: "about" */ '../views/GainsLosses.vue'),
        meta: { pageTitle: "Zyski i straty NETTO - Lauron Sp. z o.o.", title: 'Zyski i straty NETTO' },
      },
      {
        path: 'gainsLossesDiscounts',
        component: () => import(/* webpackChunkName: "about" */ '../components/GainsLossesDiscounts.vue'),
        meta: { pageTitle: "Zarządzaj rabatami - Lauron Sp. z o.o.", title: 'Zarządzaj rabatami' },
      },
      {
        path: 'createInvoice',
        component: () => import(/* webpackChunkName: "about" */ '../views/CreateInvoice.vue'),
        meta: { pageTitle: "Tworzenie faktur - Lauron Sp. z o.o.", title: 'Utwórz fakture' }
      },
      {
        path: 'warnings',
        component: () => import(/* webpackChunkName: "about" */ '../views/Warnings.vue'),
        meta: { pageTitle: "Zarządzaj upomnieniami - Lauron Sp. z o.o.", title: 'Zarządzaj upomnieniami użytkowników' }
      },
      {
        path: 'emails',
        component: () => import(/* webpackChunkName: "about" */ '../views/Emails.vue'),
        meta: { pageTitle: "Zarządzaj mailami - Lauron Sp. z o.o.", title: 'Zarządzaj wieloma mailami naraz.' }
      },
      {
        path: 'productTranslations',
        component: () => import(/* webpackChunkName: "about" */ '../views/ProductTranslations.vue'),
        meta: { pageTitle: "Tłumaczenie produktów - Lauron Sp. z o.o.", title: 'Tłumaczenie produktów' }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
