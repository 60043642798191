<template>
  <div id="app" class="min-h-screen w-screen bg-slate-600">
    <router-view />
  </div>
  
</template>
<script>
  export default {
    data() {
      return {
        local: false,
      }
    },
    watch: {
      // Set meta tags
      $route(to) {
        this.setMeta(to.meta);
      },
    },
    methods: {
      setMeta(meta) {
        document.title = meta.pageTitle;
        if(meta.title === undefined)
        {
          document.title = 'lauron-system';
        }
      }
    },
    created() {
      if(this.local) {
        this.$store.state.apiLink = "http://127.0.0.1:5001/lauron-a8b59/europe-central2/api"
      } else if(!this.local) {
        this.$store.state.apiLink = "https://europe-central2-lauron-a8b59.cloudfunctions.net/api"
      }
    },
  }
</script>
<style>

body
{
  overflow-x: hidden;
}

</style>

